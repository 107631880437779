import React from 'react'
import { Line } from 'react-chartjs-2'

interface SmallChartProps {
  host: any
  results: any[]
  maxTick: number
}

class SmallChart extends React.Component<SmallChartProps> {
  chartRef: any

  render() {
    const now = new Date()
    const labels = []
    const dataset1 = []
    const dataset2 = []
    const results = []
    const paddedResults = {}
    let kd

    for (let hour = 0; hour < 24; hour += 1) {
      for (let min = 0; min < 60; min += 5) {
        if (this.props.host.frequency === 'DAY' && (hour > 0 || min > 0)) {
          continue
        }
        if (this.props.host.frequency === 'HOUR' && min > 0) {
          continue
        }
        kd = new Date(now)
        kd.setMilliseconds(0)
        kd.setSeconds(0)
        kd.setHours(hour)
        kd.setMinutes(min)
        if (hour > now.getHours() || (hour === now.getHours() && min > now.getMinutes())) {
          kd.setDate(kd.getDate()-1)
        }
        const resultTime = kd.toISOString().slice(0, 16)
        paddedResults[resultTime] = {
          ca: kd.getTime(),
          ss: 'MISSING',
          tt: 0,
        }
        /*
        let k1 = ''+hour
        let k2 = ''+min
        if (k1.length < 2) k1 = '0' + k1
        if (k2.length < 2) k2 = '0' + k2
        if (!paddedResults[k1 + ':' + k2]) {
          paddedResults[k1 + ':' + k2] = {
            ca: kd.getTime(),
            ss: 'MISSING',
            tt: 0,
          }
        }
        */
      }
    }

    this.props.results.map(result => {
      paddedResults[result.rt] = result
    })

    const keys = Object.keys(paddedResults || {}).sort((a, b) => paddedResults[a].ca - paddedResults[b].ca)
    keys.map(timeKey => {
      const result = paddedResults[timeKey]
      results.push(result)
      labels.push(result.ca)
      dataset1.push(result.ss === 'OK' || result.ss === 'MISSING' ? result.tt : NaN)
      dataset2.push(result.ss !== 'OK' && result.ss !== 'MISSING' ? result.tt : NaN)
    })

    const chartData = {
      labels: labels,
      datasets: [{
        label: 'OK',
        fill: true,
        lineTension: 0.3,
        pointRadius: 0,
        pointHitRadius: 10,
        data: dataset1,
      }, {
        label: 'Problem',
        fill: true,
        backgroundColor: '#ee7777',
        borderColor: '#ee0000',
        pointBorderColor: '#ee0000',
        pointBackgroundColor: '#ee5555',
        lineTension: 0.3,
        pointRadius: 0,
        pointHitRadius: 2,
        data: dataset2,
      }],
    }
    const chartOptions = {
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
        custom: (tooltipModel) => {
          if (typeof document === 'undefined') return
          let tooltipEl = document.getElementById('chartjs-tooltip')
          // Create element on first render
          if (!tooltipEl) {
              tooltipEl = document.createElement('div')
              tooltipEl.id = 'chartjs-tooltip'
              tooltipEl.innerHTML = "<div></div>"
              document.body.appendChild(tooltipEl)
          }
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0'
              tooltipEl.style.display = 'none'
              return
          }
          // Set caret Position
          /*
          tooltipEl.classList.remove('above', 'below', 'no-transform')
          if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign)
          } else {
              tooltipEl.classList.add('no-transform')
          }
          */

          // Display, position, and set styles for font
          //console.log('chartRef:', this.chartRef)
          if (this.chartRef && this.chartRef.chart_instance) {
            const position = this.chartRef.chart_instance.canvas.getBoundingClientRect();
            tooltipEl.style.position = 'absolute'
            tooltipEl.style.display = ''
            tooltipEl.style.opacity = '1'
            tooltipEl.style.left = position.left + tooltipModel.caretX + 'px'
            tooltipEl.style.top = position.top + tooltipModel.caretY + 'px'
            tooltipEl.style.fontFamily = 'Helvetica,sans-serif'
            tooltipEl.style.fontSize = tooltipModel.fontSize
            tooltipEl.style.fontStyle = ''
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
            tooltipEl.innerHTML = tooltipModel.title
          }
        },
        callbacks: {
          title: (tooltip, data) => '<div class="title">' + results[tooltip[0].index].ss + ' (' + '' + new Date(results[tooltip[0].index].ca).toISOString().slice(0, 19).replace('T', ' ') + ' UTC)</div><div>Fetch time: ' + results[tooltip[0].index].tt + 'ms</div><div class="footer">HTTP Status: ' + results[tooltip[0].index].sc + '</div>' ,
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          display: false,
          ticks: {
            max: this.props.maxTick,
          },
        }],
        yAxes: [{
          display: false,
          ticks: {
            max: this.props.maxTick,
          },
        }],
      },
    }
    return (
      <Line ref={(el) => {this.chartRef = el}} height={48} data={chartData} options={chartOptions} />
    )
  }
}

export default SmallChart
