import React from 'react'
import Layout from '../components/layout'
import { Link, navigate } from 'gatsby'
import SEO from '../components/SEO'
import { SessionAuthenticator, withSessionContext, SessionContext } from 'clouden-components/context/session'
import { withNotificationContext, NotificationContext } from 'clouden-components/context/notification'
import { withCreditContext, CreditContext } from 'clouden-components/context/credit'
import { withPingContext, PingContext } from '../context/ping'
import uuidv4 from 'uuid/v4'
import config from 'react-global-configuration'
import { StripePaymentIntent } from 'clouden-components/views/stripe-payment/stripe-payment-intent'
import Decimal from 'decimal.js'
import SmallChart from '../components/SmallChart'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'
import qs from 'qs'

interface DashboardProps extends WithTranslation {
  location: any
  notificationContext: NotificationContext
  sessionContext: SessionContext
  pingContext: PingContext
  creditContext: CreditContext
}

class AuthenticatedDashboardPage extends React.Component<DashboardProps> {
  timer: any

  state = {
    newHostId: uuidv4(),
    newHostMethod: 'GET',
    newHostUrl: '',
    newHostFrequency: '5MIN',
    newHostExpectedStatus: '200',
    pendingHosts: {},
    paymentSuccess: null,
    paymentError: null,
    amount: '4.99',
    currency: 'EUR',
    confirmingPayment: false,
  }

  async componentWillMount() {
    this.props.sessionContext.fetchAccounts()
    const hostsResponse = await this.props.pingContext.fetchHosts()
    this.fetchAllHostResults(hostsResponse)
    this.props.creditContext.fetchAccount()
    this.timer = setInterval(async () => {
      if (this.props.pingContext.hosts.filter(host => host.status === 'PENDING').length > 0) {
        const hostsResponse = await this.props.pingContext.fetchHosts()
        this.fetchAllHostResults(hostsResponse)
        this.props.creditContext.fetchAccount()
      }
    }, 10000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    this.timer = null
  }

  async componentDidUpdate(prevProps: DashboardProps) {
    if (prevProps.sessionContext.currentAccountId !== this.props.sessionContext.currentAccountId) {
      const hostsResponse = await this.props.pingContext.fetchHosts()
      this.fetchAllHostResults(hostsResponse)
    }
  }

  handleRefresh = async (event: any) => {
    event.preventDefault()
    const hostsResponse = await this.props.pingContext.fetchHosts()
    this.fetchAllHostResults(hostsResponse)
    this.props.creditContext.fetchAccount()
  }

  fetchAllHostResults(hosts: any[]) {
    hosts.map((host: any) => {
      this.props.pingContext.fetchHostResults(host.hostId)
    })
  }

  onStripeCharge = () => {
    // this.props.creditContext.postPaymentNotification()
    this.props.creditContext.fetchAccount()
    this.setState({
      paymentSuccess: true,
      paymentError: null,
    })
  }

  onStripeError = (err) => {
    this.setState({
      paymentSuccess: false,
      paymentError: err,
    })
  }

  handleAddHost = (event) => {
    event.preventDefault()
    this.setState({
      newHostId: uuidv4(),
      newHostMethod: 'GET',
      newHostUrl: '',
      newHostFrequency: '5MIN',
      newHostExpectedStatus: '200',
    })
    navigate(this.props.location.pathname + '?mode=add')
  }

  handleCreateHost = async (event: any) => {
    event.preventDefault()
    if (!this.state.newHostId || !this.state.newHostMethod || !this.state.newHostUrl) return
    await this.props.pingContext.updateHost(this.state.newHostId, {
      method: this.state.newHostMethod,
      url: this.state.newHostUrl,
      frequency: this.state.newHostFrequency,
      expectedStatus: this.state.newHostExpectedStatus,
    })
    this.setState({
      newHostId: uuidv4(),
      newHostMethod: 'GET',
      newHostUrl: '',
      newHostFrequency: '5MIN',
      newHostExpectedStatus: '200',
    })
    const hostsResponse = await this.props.pingContext.fetchHosts()
    this.fetchAllHostResults(hostsResponse)
    this.props.creditContext.fetchAccount()
    navigate(this.props.location.pathname)
  }

  handleEditHost = (host: any, event: any) => {
    event.preventDefault()
  }

  handleCheckHost = async (host: any, event: any) => {
    event.preventDefault()
    this.setState({
      pendingHosts: {...this.state.pendingHosts,
        [host.hostId]: true,
      },
    })
    try {
      await this.props.pingContext.updateHost(host.hostId, {status:'PENDING'})
      const hostsResponse = await this.props.pingContext.fetchHosts()
      this.fetchAllHostResults(hostsResponse)
      this.props.creditContext.fetchAccount()
      this.setState({
        pendingHosts: {...this.state.pendingHosts,
          [host.hostId]: false,
        },
      })
    } catch (err) {
      this.setState({
        pendingHosts: {...this.state.pendingHosts,
          [host.hostId]: false,
        },
      })
    }
  }

  formatDateTime(dt: string) {
    if (!dt) return '-'
    const d = new Date(dt)
    return d.toISOString().slice(0, 16).replace('T', ' ')
  }

  handleAmountChanged = (event: any) => {
    this.setState({
      amount: event.target.value.replace(',', '.'),
    })
  }

  onStripePaymentCreated = async (paymentIntent: any) => {
    // Payment intent created - now confirm it

    this.setState({
      confirmingPayment: true,
    })

    let confirmResponse
    try {
      confirmResponse = await this.props.creditContext.confirmAndCaptureStripePaymentIntent(paymentIntent.id)
    } catch (err) {
      console.log('Failed to confirm payment:', err)
      this.props.notificationContext.addErrorNotification(err)
      this.setState({
        confirmingPayment: false,
      })
      return
    }

    console.log('Confirm response:', confirmResponse)

    this.props.notificationContext.addNotification({
      level: 'success',
      message: 'Credit deposit successful.',
    })
    this.setState({
      confirmingPayment: false,
    })
  }

  render() {
    const query = qs.parse(this.props.location.search.slice(1))
    const addingNewHost = query.mode === 'add'
    const accountBalance = this.props.creditContext.account ? new Decimal(this.props.creditContext.account.balance) : null
    const isBlankAccount = (!addingNewHost && accountBalance && !accountBalance.greaterThan(0) && this.props.pingContext.hosts.length <= 0)
    let maxTick = 10
    const oldestResult = new Date()
    oldestResult.setDate(oldestResult.getDate() - 1)
    for (let hostIndex in this.props.pingContext.hosts) {
      const host = this.props.pingContext.hosts[hostIndex]
      const results = this.props.pingContext.hostResultsMap[host.hostId] || []
      for (let resultIndex in results) {
        const result = results[resultIndex]
        if (result.tt > maxTick && result.ca >= oldestResult.getTime()) {
          maxTick = result.tt
        }
      }
    }
    const activeHosts = this.props.pingContext.hosts.filter(host => host.frequency !== 'DISABLED').length

    const minimumPayment = new Decimal(config.get('MINIMUM_PAYMENT'))
    const maximumPayment = new Decimal(config.get('MAXIMUM_PAYMENT'))
    const taxPercent = new Decimal(config.get('TAX_PERCENT'))
    let depositAmount
    try {
      depositAmount = this.state.amount && new Decimal(this.state.amount)
    } catch (err) {
      depositAmount = null
    }
    const taxAmount = depositAmount.times(taxPercent).round().dividedBy(100)
    let stripeAmount = depositAmount ? depositAmount.plus(taxAmount) : null
    if (stripeAmount && (!stripeAmount.greaterThanOrEqualTo(minimumPayment) || !stripeAmount.lessThanOrEqualTo(maximumPayment) || !stripeAmount.equals(stripeAmount.toDecimalPlaces(2, Decimal.ROUND_FLOOR)))) {
      stripeAmount = null
    }
    console.log('blank', isBlankAccount, 'stripe', stripeAmount)

    return (
      <div className="container">
        <div className="row flex-button-row">
          <div className="col-lg-8">
            <h1 className="mt-5"><Trans i18nKey="dashboard_title">Monitored Hosts</Trans></h1>
          </div>
          <div className="col-lg-4 flex-button-col">
            <button disabled={addingNewHost || isBlankAccount} className="btn btn-primary" onClick={this.handleAddHost}><span className="fa fa-plus"></span>{' '}<Trans i18nKey="dashboard_add_host">Add Host</Trans></button>
            {' '}
            <button className="btn btn-secondary" onClick={this.handleRefresh}><span className={this.props.pingContext.loadingHosts ? "fa fa-sync-alt fa-spin" : "fa fa-sync-alt"}></span>{' '}<Trans i18nKey="dashboard_refresh">Refresh</Trans></button>
          </div>
        </div>
        {!isBlankAccount && accountBalance && accountBalance.lessThan(0.01) ? <div className="alert alert-warning">Your account balance is exhausted and all pings are disabled. <Link to="/account/?tab=credits">Click here to deposit credits</Link></div> : null}
        {addingNewHost ?
        <form onSubmit={this.handleCreateHost}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label><Trans i18nKey="dashboard_url_to_monitor">URL to monitor</Trans></label>
                <input type="text" className="form-control" placeholder={this.props.t("dashboard_url_placeholder", "Enter full URL such as https://example.com")} value={this.state.newHostUrl} onChange={(event) => this.setState({newHostUrl:event.target.value})}/>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label><Trans i18nKey="dashboard_frequency">Frequency</Trans></label>
                <select className="form-control" value={this.state.newHostFrequency} onChange={(event) => this.setState({newHostFrequency:event.target.value})}>
                  <option value="DISABLED">DISABLED</option>
                  <option value="5MIN">5MIN</option>
                  <option value="HOUR">HOUR</option>
                  <option value="DAY">DAY</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label><Trans i18nKey="dashboard_http_method">HTTP Method</Trans></label>
                <select className="form-control" value={this.state.newHostMethod} onChange={(event) => this.setState({newHostMethod:event.target.value})}>
                  <option>GET</option>
                  <option>HEAD</option>
                  <option>POST</option>
                  <option>PUT</option>
                  <option>DELETE</option>
                  <option>CONNECT</option>
                  <option>OPTIONS</option>
                  <option>TRACE</option>
                  <option>PATCH</option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <button type="submit" className="btn btn-primary"><Trans i18nKey="dashboard_add_host">Add Host</Trans></button>
            {' '}
            <button type="button" className="btn btn-secondary" onClick={(event) => navigate(this.props.location.pathname)}><Trans i18nKey="dashboard_cancel">Cancel</Trans></button>
          </div>
          <br/>
        </form> : null}
        {this.props.pingContext.hosts.length > 0 ?
        <ul className="list-group">
          {this.props.pingContext.hosts.map(host => {
            const results = this.props.pingContext.hostResultsMap[host.hostId] || []
            return (
              <li className="list-group-item" key={host.hostId}>
                <div className="row">
                  <div className="col-lg-5">
                    <div className="host-status">
                      <span className={host.status === 'OK' ? "badge badge-success" : host.status === 'PENDING' ? "badge badge-warning" : "badge badge-danger"}>{host.status}</span>
                      {' '}
                      <a target="_blank" rel="noopener" href={host.url}>{host.url}</a>
                    </div>
                    <div className="text-muted">
                      <span>{this.formatDateTime(host.checkedAt)}</span>
                      <span className="text-muted"> :: </span>
                      <span>{host.frequency}{host.instantRetry ? '(' + host.instantRetry + 'R)' : ''}</span>
                      <span className="text-muted"> :: </span>
                      <span>HTTP {host.statusCode}</span>
                      <span className="text-muted"> :: </span>
                      <span>{typeof(host.totalTime) === 'number' ? '' + host.totalTime + 'ms' : '-'}</span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    {results ? <SmallChart host={host} results={results} maxTick={maxTick}/> : null}
                  </div>
                  <div className="col-lg-4">
                    <div className="host-buttons">
                      <Link to={"/results/?" + qs.stringify({ hostId: host.hostId })} className="btn btn-secondary"><span className="fa fa-database"></span> <Trans i18nKey="dashboard_view_results">Results</Trans></Link>
                      {' '}
                      <button disabled={this.state.pendingHosts[host.hostId]} className="btn btn-secondary" onClick={this.handleCheckHost.bind(this, host)}><span className="fa fa-sync-alt"></span> <Trans i18nKey="dashboard_ping_now">Ping Now</Trans></button>
                      {' '}
                      <Link to={"/edit-host/?" + qs.stringify({ hostId: host.hostId })} className="btn btn-secondary"><span className="fa fa-edit"></span> <Trans i18nKey="dashboard_edit">Edit</Trans></Link>
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul> : null}
        {!isBlankAccount && !this.props.pingContext.loadingHosts && !this.props.pingContext.hosts.length && !addingNewHost ?
        <div className="alert alert-secondary">No hosts configured yet. <a href="#" onClick={this.handleAddHost}>Click here to add one</a></div>
        : null}
        {this.props.pingContext.hosts.length >= 0 ?
        <div className="mt-1">
          <ul className="list-group">
          <li className="list-group-item">
          <span><Trans i18nKey="dashboard_active_hosts" count={activeHosts}>Total {{count:activeHosts}} of {{totalHosts:this.props.pingContext.hosts.length}} host(s) are active.</Trans></span>
          {' '}
          {this.props.creditContext.account ?
          <span>
            <Trans i18nKey="dashboard_account_balance">Your account has {{balance:this.props.creditContext.account.balance}} EUR of credits left.</Trans>{' '}
            <Link to={"/account/?" + qs.stringify({ tab: 'credits' })}><Trans i18nKey="dashboard_add_credits">Deposit Credits</Trans></Link>
          </span>
          : null}
          </li>
          </ul>
        </div> : null}
        {isBlankAccount ?
        <div className="well bg-white p-4">
          <h3 style={{marginTop:0}}>Welcome to Clouden Ping!</h3>
          <p>Your account balance is currently {accountBalance.toString()} EUR and you will need to add some
          credits before you can ping your websites.</p>
          <hr/>
          <div className="row">
            <div className="col-lg-12">
              <form>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Deposit Credits Now</label>
                      <div className="input-group">
                        <input type="text" className="form-control" value={this.state.amount} onChange={this.handleAmountChanged}/>
                        <span className="input-group-text input-group-append">EUR</span>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="help-block" style={{color:'#ccc'}}>Minimum amount to deposit is 1.99 EUR. Once you've added credits, host pinging will remain enabled until your account balance drops below 0.01 EUR.</p>
              </form>
              {this.state.confirmingPayment ?
              <div className="alert alert-secondary"><span className="fa fa-sync-alt fa-spin"></span> Processing payment...</div>
              : stripeAmount && stripeAmount.greaterThan(0) ?
              <StripePaymentIntent amount={depositAmount.toFixed(2)} taxAmount={taxAmount.toFixed(2)} taxPercent={taxPercent.toString()} fullAmount={stripeAmount.toFixed(2)} description={'Deposit Credits'} currency={this.state.currency} onPaymentCreated={this.onStripePaymentCreated} />
              : null}
              {/*<StripeCharge stripeKey={config.get('STRIPE_KEY')} stage={config.get('STAGE')} accessToken={this.props.accessToken} onCharge={this.onStripeCharge} onError={this.onStripeError} amount={stripeAmount.times(100).toNumber()} currency="EUR" name="Clouden" description={'Add ' + stripeAmount.toFixed(2) + ' credits to Clouden account'} />*/}
              </div>
          </div>
        </div> : null}
        {this.props.pingContext.loadingHosts ?
        <div className="mt-1">
          <ul className="list-group">
            <li className="list-group-item">
              <span className="fa fa-sync-alt fa-spin"></span> <Trans i18nKey="dashboard_loading">Loading...</Trans>
            </li>
          </ul>
        </div> : null}
      </div>
    )
  }
}

class DashboardPage extends React.Component<DashboardProps> {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title={"Dashboard"} description="Clouden Ping" />
        <SessionAuthenticator>
          <AuthenticatedDashboardPage {...this.props} />
        </SessionAuthenticator>
      </Layout>
    )
  }
}

export default withTranslation()(withNotificationContext(withSessionContext(withCreditContext(withPingContext(DashboardPage)))))
